import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

import RevealOnScroll from "../../_shared/RevealOnScroll"

import SERVICES from "./SERVICES.js"
import ServiceCard from "./ServiceCard"

//------------------------------------------------------------------------------

function ServicesVerticalCarousel(props) {
  // -------------------------------------
  // Props destructuring
  // -------------------------------------

  // -------------------------------------
  // Hooks (e.g. useState, useMemo ...)
  // -------------------------------------

  // -------------------------------------
  // Effects
  // -------------------------------------

  // -------------------------------------
  // Component functions
  // -------------------------------------
  function renderServicesCard() {
    const servicesNumber = SERVICES && SERVICES.length

    return (
      SERVICES &&
      SERVICES.map((service, index) => {
        const cardNumber = index + 1
        return (
          <RevealOnScroll key={index}>
            <ServiceCard
              cardsNumber={servicesNumber}
              currentCardNumber={cardNumber}
              icon={service.icon}
              headerTitle={service.headerTitle}
              title={service.title}
              buttonTitle={service.buttonTitle}
              buttonLink={service.buttonLink}
              subServices={service.subServices}
              hoverColor={service.cardHoverColor}
            />
          </RevealOnScroll>
        )
      })
    )
  }
  // -------------------------------------
  // Component local variables
  // -------------------------------------

  return <div className={props.className}>{renderServicesCard()}</div>
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

ServicesVerticalCarousel.propTypes = {
  className: PropTypes.string.isRequired,
}

ServicesVerticalCarousel.defaultProps = {}

// ----------------------------------------------------------------------------

const StyledServicesVerticalCarousel = styled(ServicesVerticalCarousel)`
  & {
    margin-top: 86px;
    margin-bottom: 123px;
    display: flex;
    flex-direction: column;
    align-items: center;

    > * {
      margin-bottom: 40px;
    }

    /* MOBILE */

    @media (max-width: ${props => props.theme.mediaQueries.mobile}) {
      box-sizing: border-box;
      padding: 0 26px;
      max-width: 100vw;
      overflow: hidden;

      > div {
        max-width: 100%;
      }

      > * {
        margin-bottom: 26px;
      }
    }
  }
`
// ----------------------------------------------------------------------------

export default StyledServicesVerticalCarousel
