import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import styled, { css, withTheme } from "styled-components"
import { Link } from "gatsby"
import AnimateHeight from "react-animate-height"

import RoundedButton from "../../../_shared/RoundedButton"
import FloatingButton from "../../../_shared/FloatingButton"

//------------------------------------------------------------------------------

function ServiceCard(props) {
  // -------------------------------------
  // Props destructuring
  // -------------------------------------

  const {
    icon,
    subServices,
    headerTitle,
    title,
    buttonLink,
    buttonTitle,
  } = props

  // -------------------------------------
  // Hooks (e.g. useState, useMemo ...)
  // -------------------------------------

  const defaultCollapsed = !isMobile() ? true : false

  const [isCollapsed, setIsCollapsed] = useState(defaultCollapsed)

  // -------------------------------------
  // Effects
  // -------------------------------------

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("resize", handleWindowResize)
    }
  }, [])

  // -------------------------------------
  // Component functions
  // -------------------------------------

  function handleWindowResize() {
    const isCardCollapsed = !isMobile() ? true : false

    setIsCollapsed(isCardCollapsed)
  }

  function isMobile() {
    const { theme } = props

    if (typeof window !== "undefined") {
      const mediaQuery = theme.mediaQueries && theme.mediaQueries.mobile

      const matchMediaString = `(max-width: ${mediaQuery})`

      const matchMedia = window.matchMedia(matchMediaString)

      if (matchMedia.matches) {
        return true
      }
    }

    return false
  }

  function handleCardCollapse() {
    if (isCollapsed) {
      setIsCollapsed(false)
    } else {
      setIsCollapsed(true)
    }
  }

  function renderSubServices() {
    return (
      subServices &&
      subServices.map((subService, index) => {
        const isInner = index > 0
        return (
          <div
            key={subService.title}
            className={`sub-service${isInner ? " inner-item" : ""}`}
          >
            <h4>{subService.title}</h4>
            <p>{subService.description}</p>
          </div>
        )
      })
    )
  }

  function mapTextWithNewLines(text) {
    if (!text.match(/\n/g)) {
      return text
    }

    const replacer = "<br />"

    const newText = text && text.replace(/\n/g, replacer)

    return newText
  }

  // -------------------------------------
  // Component local variables
  // -------------------------------------

  const mappedTitle = mapTextWithNewLines(title)

  const btnIcon = isCollapsed
    ? require("../../../../images/_shared/icons/less-icn.svg")
    : require("../../../../images/_shared/icons/plus-icn.svg")

  return (
    <div className={props.className}>
      <div className="content-container">
        <img className="service-icn" src={icon} />

        <div className="service-description">
          <h6>{headerTitle}</h6>
          <h2 dangerouslySetInnerHTML={{ __html: mappedTitle }} />

          <AnimateHeight
            className="animate-height-container"
            duration={300}
            height={isCollapsed ? "auto" : 0}
          >
            <div className="sub-services-container">{renderSubServices()}</div>
          </AnimateHeight>

          <div className="btn-container">
            {isCollapsed && (
              <Link className="link-container" to={buttonLink}>
                <RoundedButton className="card-cta-btn" type="grey" width={148}>
                  {buttonTitle}
                </RoundedButton>
              </Link>
            )}

            <FloatingButton
              className="floating-btn"
              onClick={handleCardCollapse}
              icon={btnIcon}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

ServiceCard.propTypes = {
  className: PropTypes.string.isRequired,
  cardsNumber: PropTypes.number,
  currentCardNumber: PropTypes.number,
  icon: PropTypes.any,
  subServices: PropTypes.array,
  headerTitle: PropTypes.string,
  title: PropTypes.string,
  buttonLink: PropTypes.string,
  buttonTitle: PropTypes.string,
  theme: PropTypes.object,
}

ServiceCard.defaultProps = {
  cardsNumber: 0,
  currentCardNumber: 0,
  icon: null,
  subServices: [],
  headerTitle: "HEADER",
  title: "Titolo del servizio",
  buttonLink: null,
  buttonTitle: "Click me!",
}

// ----------------------------------------------------------------------------

const ServiceCardWithTheme = withTheme(ServiceCard)

// ----------------------------------------------------------------------------

const StyledServiceCard = styled(ServiceCardWithTheme)`
  & {
    display: flex;
    flex-direction: column;
    border: 1px solid ${props => props.theme.ashGrey};
    border-radius: 4px;
    padding: 26px 29px;
    box-sizing: border-box;
    width: 958px;
    max-width: 958px;
    transition: all 0.3s ease-in-out;

    .current-slide-bar {
      display: flex;
      flex-direction: row;

      > * {
        margin-right: 5px;
      }
    }

    .content-container {
      margin-top: 36.7px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;

      .service-icn {
        margin-left: 58.4px;
        margin-top: 25.5px;
      }

      .service-description {
        flex: 1;
        margin-left: 100px;

        > h6 {
          margin-bottom: 10px;
          font-size: 14px;
          line-height: 18px;
          font-weight: 600;
          color: ${props => props.theme.yaleBlue};
        }

        > h2 {
          max-width: 400px;
          color: ${props => props.theme.primaryTextColor};
        }

        .sub-services-container {
          margin-top: 39.7px;
          display: flex;
          flex-direction: row;

          .sub-service {
            h4 {
              margin-bottom: 8px;
              font-size: 21px;
              line-height: 24px;
              font-weight: 600;
              color: ${props => props.theme.primaryTextColor};
            }

            p {
              font-size: 18px;
              line-height: 24px;
              font-weight: normal;
              color: ${props => props.theme.primaryTextColor};
            }
          }

          .inner-item {
            margin-left: 46px;
          }
        }

        .btn-container {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-end;

          .card-cta-btn {
            margin-top: 76px;
            float: right;
          }

          .floating-btn {
            display: none;
          }
        }
      }
    }

    :hover {
      ${props =>
        props.hoverColor &&
        css`
          background-color: ${props => props.theme[props.hoverColor]};
          border-color: transparent;
          box-shadow: 0 3px 12px rgba(0, 0, 0, 0.11);

          .floating-btn {
            background-color: #ffffff !important;
          }

          .card-cta-btn {
            background-color: #ffffff !important;
          }
        `}
    }

    /* MOBILE */

    @media (max-width: ${props => props.theme.mediaQueries.mobile}) {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 18px 15px;
      box-sizing: border-box;
      max-width: 100%;

      .content-container {
        margin-top: 50px;
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 100%;

        .service-icn {
          margin-left: 0;
          margin-top: 0;
          margin-bottom: 50px;
        }

        .service-description {
          flex: 1;
          margin-left: 0;
          max-width: 100%;

          > h6 {
            max-width: 100%;
            text-align: center;
          }

          > h2 {
            margin-bottom: 27.7px;
            max-width: 100%;
            text-align: center;
          }

          .sub-services-container {
            flex-direction: column;

            .sub-service {
              margin-bottom: 20px;

              h4 {
                text-align: center;
              }

              p {
                text-align: center;
              }
            }

            .inner-item {
              margin-left: 0;
            }
          }

          .btn-container {
            justify-content: center;

            .link-container {
              display: flex;
              flex-direction: row;
              justify-content: center;

              margin-right: 17px;
              text-decoration: none;

              .card-cta-btn {
                margin-top: 0;
                float: none;
              }
            }

            .floating-btn {
              display: block;
            }
          }
        }
      }
    }
  }
`
// ----------------------------------------------------------------------------

export default StyledServiceCard
