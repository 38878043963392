import React from "react"

import Layout from "../components/_shared/Layout"
import SEO from "../components/_shared/SEO"
import BannerHeroImage from "../components/_shared/BannerHeroImage"
import ContactUsSection from "../components/_shared/ContactUsSection"

import ServicesVerticalCarousel from "../components/Services/ServicesVerticalCarousel"

// ----------------------------------------------------------------------------
const ServicesPage = () => (
  <Layout pageTitle="Servizi">
    <SEO title="Servizi" />

    <BannerHeroImage
      image={require("../images/services/hero.png")}
      bannerText={`Scegli l’efficienza e la rapidità\ndei servizi Sales Bridge per la\nvendita online `}
      highlightedText={"l’efficienza e la rapidità"}
    />

    <ServicesVerticalCarousel />
    <ContactUsSection />
  </Layout>
)

export default ServicesPage
