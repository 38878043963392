import React from "react"
import { Link } from "gatsby"
import RoundedButton from "../../_shared/RoundedButton"

// ----------------------------------------------------------------------------

export default [
  {
    headerTitle: "CONSULENZA",
    title: "La ricerca del marketplace più adatto",
    icon: require("../../../images/services/icons/consulting-black-icn.svg"),
    subServices: [
      {
        title: "Analisi del business",
        description:
          "Studiamo ed analizziamo il mercato per ampliare i tuoi strumenti di vendita online.",
      },
      {
        title: "Scelta dei marketplace",
        description:
          "Selezioniamo la strategia di posizionamento e vendita più appropriata per la tua attività.",
      },
    ],
    buttonTitle: "CONTATTACI",
    buttonLink: "/contacts",
    cardHoverColor: "pearl",
  },
  {
    headerTitle: "CATALOGO",
    title: "Ottimizzazione del catalogo online",
    icon: require("../../../images/services/icons/catalog-icn.svg"),
    subServices: [
      {
        title: "Editing",
        description:
          "Processiamo i testi del catalogo per posizionare in maniera ottimale i tuoi prodotti nel nostro network di vendita.",
      },
      {
        title: "Visual",
        description:
          "Indichiamo tutte le procedure per l’ottimizzazione delle immagini del catalogo da caricare online.",
      },
    ],
    buttonTitle: "AZIENDA",
    buttonLink: "/company",
    cardHoverColor: "pearlAqua",
  },
  {
    headerTitle: "ECOMMERCE",
    title: "Pubblicazione\ne vendita",
    icon: require("../../../images/services/icons/bag-icn.svg"),
    subServices: [
      {
        title: "Pubblicazione del catalogo",
        description:
          "Provvediamo a pubblicare il catalogo nella nostra rete di vendita online.",
      },
      {
        title: "Gestione delle vendite",
        description:
          "Emettiamo ordini d’acquisto secondo la domanda di mercato e ci prendiamo cura di tutte le fasi di vendita.",
      },
    ],
    buttonTitle: "FAQ",
    buttonLink: "/faq",
    cardHoverColor: "jonquil",
  },
  {
    headerTitle: "LOGISTICA",
    title: "Una logistica snella",
    icon: require("../../../images/services/icons/logistics-black-icn.svg"),
    subServices: [
      {
        title: "Spedizioni mondiali",
        description:
          "Spediamo in tutto il mondo con efficienti e consolidati servizi.",
      },
      {
        title: "Cross docking o drop shipping",
        description:
          "Individuiamo il servizio logistico più adatto alle tue esigenze.",
      },
    ],
    buttonTitle: "CONTATTACI",
    buttonLink: "/contacts",
    cardHoverColor: "pearl",
  },

  {
    headerTitle: "CUSTOMER CARE",
    title: "Con noi solo clienti soddisfatti",
    icon: require("../../../images/services/icons/thumb-icn.svg"),
    subServices: [
      {
        title: "Assistenza ottimale",
        description:
          "Siamo a disposizione per qualsiasi tipo di richiesta via telefono, mail, Skype e Whatsapp.",
      },
      {
        title: "Cinque lingue",
        description:
          "Offriamo agli acquirenti un servizio di assistenza sempre puntuale e multilingua.",
      },
    ],
    buttonTitle: "FAQ",
    buttonLink: "/faq",
    cardHoverColor: "pearlAqua",
  },

  {
    headerTitle: "PARTNER CARE",
    title: "Resta sempre aggiornato",
    icon: require("../../../images/services/icons/trend-icn.svg"),
    subServices: [
      {
        title: "Insights",
        description:
          "Ti teniamo informato sullo stato dei cataloghi e sulle più redditizie evoluzioni del mercato.",
      },
      {
        title: "Key Account",
        description:
          "Ti affianchiamo in tutte le fasi del processo di vendita con un consulente esperto dedicato.",
      },
    ],
    buttonTitle: "AZIENDA",
    buttonLink: "/company",
    cardHoverColor: "jonquil",
  },
]
